import React from 'react'
import { Link } from 'gatsby'
import { FaBars, FaTimes } from 'react-icons/fa'
import Logo from '../svg/logo_bleu.svg'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.onBurgerClick = this.onBurgerClick.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
    this.onLinkClick = this.onLinkClick.bind(this)
  }

  onBurgerClick() {
    if (typeof document != `undefined`) {
      const menuPrincipal = document.getElementById('menuPrincipal')
      const html = document.querySelector('html')

      menuPrincipal.closest('.navbar').classList.add('is-visible')
      html.classList.add('is-clipped')
    }
  }

  onCloseClick() {
    if (typeof document != `undefined`) {
      const menuPrincipal = document.getElementById('menuPrincipal')
      const html = document.querySelector('html')

      menuPrincipal.closest('.navbar').classList.remove('is-visible')
      html.classList.remove('is-clipped')
    }
  }

  onLinkClick() {
    this.onCloseClick()
  }

  render() {
    const currentSlug = this.props.pageSlug ? this.props.pageSlug : ''

    return (
      <header className='header site-header'>
        <div className="container">
          <div className="columns">
            <div className="column is-4-tablet site-logo-header-wrapper">
              <Link to='/' className="site-logo" title="Retour à l'Accueil">
                <Logo/>
              </Link>
            </div>
          </div>
        </div>
        <nav className="navbar">
          <div className="container is-fluid">
            <button className="navbar-burger burger" onClick={this.onBurgerClick} aria-label="menu" aria-expanded="false" data-target="menuPrincipal">
              <FaBars />
            </button>
            <div className="navbar-menu" id="menuPrincipal">
              <span className="navbar-close close" onClick={this.onCloseClick}>
                <FaTimes />
              </span>
              <div className="navbar-centered">
                { this.props.elementsMenu.map( (elementMenu, index) => {
                  const slug = elementMenu.page.slug.current
                  const isActive = slug === currentSlug
                  const pageTitle = elementMenu.title
                  const href = `/${slug}`
                  const menuItemClasses = `navbar-item ${ isActive ? 'is-active' : ''}`
                  return <Link className={menuItemClasses} key={index} to={href} onClick={this.onLinkClick}>{pageTitle}</Link>
                })}
              </div>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
