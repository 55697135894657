import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import 'typeface-montserrat'
import 'typeface-merriweather'
import '../styles/main.scss'

export default (location) => {
  const children = location.children
  const pageContext = location.pageContext
  return (
    <StaticQuery
      query={graphql`
        query SiteSettingsQuery {
          site {
            siteMetadata {
              title
            }
          }
          sanitySiteMenus {
            mainNavigation {
              title
              page {
                slug {
                  current
                }
              }
            }
            legalNavigation {
              title
              page {
                slug {
                  current
                }
              }
            }
          }
        }
      `}
      render={data => {
        const pageTitle = location.data ? location.data.sanityPage ? location.data.sanityPage.title : "" : ""
        const pageSlug = pageContext ? pageContext.slug : ""
        let wrapperClasses = []
        wrapperClasses.push(`wrapper`)
        if (pageSlug) {
          pageSlug.current === '/' ? wrapperClasses.push(`is-page-home`) : wrapperClasses.push(`is-page-${pageSlug.current}`)
        } else {
          wrapperClasses.push(`is-page-404`)
        }

        return (
          <div className={wrapperClasses.join(' ')}>
            <Header siteTitle={data.site.siteMetadata.title} pageTitle={pageTitle} elementsMenu={data.sanitySiteMenus.mainNavigation} pageSlug={pageSlug} location={location} />
            <main className="main">
              {children}
            </main>
            <Footer menuLegal={data.sanitySiteMenus.legalNavigation} />
          </div>
      )}}
    />
  )
}
