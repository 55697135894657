import React from 'react'
import { Link } from 'gatsby'
import Logo from '../svg/logo_bleu.svg'
import { FaInstagram } from "react-icons/fa";

const Footer = ({ menuLegal, pageSlug }) => {
  const currentSlug = pageSlug ? pageSlug : ''

  return (
    <footer className="site-footer">
      <div className="container is-fluid">
        <div className="columns is-mobile">
          <div className="column is-2-tablet is-offset-2-tablet is-12-mobile site-logo-wrapper">
            <Link to="/" className="has-no-style site-logo" title="Retour à l'Accueil">
              <Logo/>
            </Link>
          </div>
          <div className="column is-5-tablet is-12-mobile">
            <div className="content contact">
              <p>Valérie Chenain « Pilates Home Studio »<br/>
              <a href="https://www.google.com/maps/dir//20+Rue+Ninau,+31000+Toulouse,+France/@43.5960731,1.4343206,14.14z/data=!4m17!1m7!3m6!1s0x12aebc8458c314cd:0xb99b84246a88a74d!2s20+Rue+Ninau,+31000+Toulouse,+France!3b1!8m2!3d43.5969998!4d1.4498691!4m8!1m0!1m5!1m1!1s0x12aebc8458c314cd:0xb99b84246a88a74d!2m2!1d1.4498691!2d43.5969998!3e1" target="_blank" rel="noopener noreferrer">20 rue Ninau - 31000 Toulouse</a><br/>
              Tel : 06 83 02 45 99 - <a href="mailto:contact@valeriechenain-pilates.com">contact@valeriechenain-pilates.com</a><br/>
              <a href="https://www.instagram.com/valeriechenainpilates/" target="_blank" rel="noopener noreferrer"><FaInstagram/> Instagram</a></p>

              <div className="legal-navigation">
                { menuLegal && menuLegal.map( (elementMenu, index) => {
                  const slug = elementMenu.page.slug.current
                  const isActive = slug === currentSlug
                  const pageTitle = elementMenu.title
                  const href = `/${slug}`
                  const menuItemClasses = `legal-link ${ isActive ? 'is-active' : ''}`
                  return <Link className={menuItemClasses} key={index} to={href}>{pageTitle}</Link>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="credits">
        Design & Développement : <a href="https://www.cerealconcept.com" target="_blank" rel="noopener noreferrer">Cereal Concept</a>
      </div>
    </footer>
  )
}

export default Footer
