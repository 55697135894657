import React from 'react'
import { Link } from 'gatsby'
import Logo from '../svg/logo_bleu.svg'
import { FaInstagram } from "react-icons/fa";

const Footer = ({ menuLegal, pageSlug }) => {
  const currentSlug = pageSlug ? pageSlug : ''

  return (
    <footer className="site-footer">
      <div className="container is-fluid">
        <div className="columns is-mobile">
          <div className="column is-2-tablet is-offset-2-tablet is-12-mobile site-logo-wrapper">
            <Link to="/" className="has-no-style site-logo" title="Retour à l'Accueil">
              <Logo/>
            </Link>
          </div>
          <div className="column is-5-tablet is-12-mobile">
            <div className="content contact">
              <p>Valérie Chenain « Pilates Home Studio »<br/>
              <a href="https://www.google.fr/maps/place/C%2F+de+la+Reina+Vict%C3%B2ria,+21,+Sarri%C3%A0-Sant+Gervasi,+08021+Barcelona,+Espagne/@41.3968848,2.135551,17z/data=!3m1!4b1!4m6!3m5!1s0x12a49873d0ff49f5:0xc4ba702a09577efd!8m2!3d41.3968808!4d2.1381259!16s%2Fg%2F11c1zjxbln?entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">Carrer Reina Victoria 21, Barcelona</a><br/>
              Tel : +33 6 83 02 45 99 ou +34 625 638 478 - <a href="mailto:contact@valeriechenain-pilates.com">contact@valeriechenain-pilates.com</a><br/>
              <a href="https://www.instagram.com/valeriechenainpilates/" target="_blank" rel="noopener noreferrer"><FaInstagram/> Instagram</a></p>

              <div className="legal-navigation">
                { menuLegal && menuLegal.map( (elementMenu, index) => {
                  const slug = elementMenu.page.slug.current
                  const isActive = slug === currentSlug
                  const pageTitle = elementMenu.title
                  const href = `/${slug}`
                  const menuItemClasses = `legal-link ${ isActive ? 'is-active' : ''}`
                  return <Link className={menuItemClasses} key={index} to={href}>{pageTitle}</Link>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="credits">
        Design & Développement : <a href="https://www.cerealconcept.com" target="_blank" rel="noopener noreferrer">Cereal Concept</a>
      </div>
    </footer>
  )
}

export default Footer
